@font-face{
    font-family: "Houschka Pro Medium";
    src: url("./../fonts/Houschka\ Pro\ Medium.eot"),
    // src: url("./../fonts/225646b777438f81ec8400ff378afe2d.eot?#iefix")format("embedded-opentype"),
        url("./../fonts/Houschka\ Pro\ Medium.woff")format("woff"),
        // url("./../fonts/225646b777438f81ec8400ff378afe2d.woff2")format("woff2"),
        url("./../fonts/Houschka\ Pro\ Medium.ttf")format("truetype");
        // url("./../fonts/225646b777438f81ec8400ff378afe2d.svg#Houschka Pro Medium")format("svg");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}