.navbar {
  background: transparent !important;
  padding: 20px 0px;
  transition: all 0.8s ease;
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  font-family: $font-secondary;
  .navbar-brand {
    img {
      max-width: 170px;
      //   transition: all 0.2s ease;
    }
  }
  .navbar-nav-scroll {
    margin-left: auto;
  }
  .nav-link {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    padding: 5px 20px !important;
    &:after {
      content: "";
      display: block;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $color-primary;
      transform-origin: bottom right;
      transition: transform 0.5s ease-out;
    }
    &:hover {
      &:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
  .navbar-toggler {
    background: $color-secondary;
  }
}
.menubar-shrink {
  .navbar {
    padding: 0;
    position: fixed;
    transition: all 0.8s ease;
    background: #fff !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    .brand-wide {
      display: none;
    }
    .brand-shrink {
      display: block;
      &.navbar-brand {
        img {
          max-width: 120px;
          //   transition: all 0.2s ease;
        }
      }
    }
    .nav-link {
      color: #000;
    }
  }
}
.menubar-wide {
  .navbar {
    .brand-wide {
      display: block;
    }
    .brand-shrink {
      display: none;
    }
    @media screen and (max-width: 990px) {
      .navbar-collapse {
        background: $color-secondary;
      }
    }
  }
}
