@charset "UTF-8";
@font-face {
  font-family: "Houschka Pro Medium";
  src: url("./../fonts/Houschka Pro Medium.eot"), url("./../fonts/Houschka Pro Medium.woff") format("woff"), url("./../fonts/Houschka Pro Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  font-family: "Houschka Pro Medium", Arial, sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: #231f1e;
}

h1 {
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Jost", Arial, sans-serif;
}

h2 {
  font-size: 32px;
  color: #49b747;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 26px;
  }
}

.main-container {
  margin-top: 0px;
}
.main-container .who-we-are-content {
  background: #309ad6;
  padding: 50px 0px;
}
@media screen and (max-width: 991px) {
  .main-container .who-we-are-content {
    background: #309ad6 url("./../images/who-we-are.png") no-repeat;
    background-size: cover;
    background-blend-mode: soft-light;
  }
}
.main-container .who-we-are-content img {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .main-container .who-we-are-content img {
    display: none;
  }
}
.main-container .who-we-are-content .outline-success {
  border-radius: 6px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
}
.main-container .who-we-are-content .outline-success:hover {
  background: #49b747;
  color: #fff;
}
.main-container .banner-card {
  border: 0;
  margin-right: -50px;
  padding: 20px;
}
@media screen and (max-width: 991px) {
  .main-container .banner-card {
    margin-right: 0;
  }
}
.main-container .home-our-services {
  padding: 50px 0px;
}
.main-container .home-our-services .card {
  border: 0;
  margin: 10px 0px 30px;
  transition: transform 0.2s;
  overflow: hidden;
  cursor: pointer;
}
.main-container .home-our-services .card .card-img-top {
  transition: all 0.3s ease-in-out;
}
.main-container .home-our-services .card .card-body {
  background: rgba(255, 255, 255, 0.8);
  margin: 10px;
  margin-top: -50px;
  font-size: 14px;
  position: relative;
  z-index: 9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.main-container .home-our-services .card .card-body .card-title {
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 14px;
  font-family: "Jost", Arial, sans-serif;
}
@media screen and (max-width: 990px) {
  .main-container .home-our-services .card .card-body .card-title {
    font-size: 20px;
  }
}
.main-container .home-our-services .card:hover .card-img-top {
  transform: scale(1.2);
}
.main-container .home-our-services .card:hover .card-body {
  background: #309ad6;
  color: #fff;
}
.main-container .how-we-work-content {
  padding: 50px 0px;
  background: url(./../images/how-we-work.jpg) no-repeat fixed;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  min-height: 450px;
  color: #fff;
  text-align: center;
}
.main-container .how-we-work-content h1 {
  margin-bottom: 50px;
}
.main-container .how-we-work-content .outline-success {
  border-radius: 6px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  color: #fff;
}
.main-container .how-we-work-content .outline-success:hover {
  background: #49b747;
  color: #fff;
}
.main-container button, .main-container .anchor-button {
  font-family: "Jost", Arial, sans-serif;
  border: 2px solid #49b747;
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 30px;
  display: flex;
  align-self: flex-end;
  margin-top: 40px;
}

.inner-content {
  padding: 50px 0px;
}

.grey-container {
  background: #f4f7f9;
}

.brand-name {
  font-family: "Jost", Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.banner-container h1 {
  font-size: 64px;
  line-height: 16px;
  color: #fff;
  text-transform: none;
  font-weight: 600;
  font-family: "Jost", Arial, sans-serif;
  margin-bottom: 50px;
}
.banner-container h2 {
  font-size: 16px;
  color: #fff;
  font-family: "Jost", Arial, sans-serif;
  line-height: 29px;
}

.swiper img {
  height: auto;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .swiper img {
    height: 100vh;
    width: auto;
  }
}
.swiper p {
  position: absolute;
  font-size: 40px;
  z-index: 9;
  top: 45%;
  color: #fff;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 990px) {
  .swiper p {
    font-size: 22px;
  }
}
@media screen and (max-width: 768px) {
  .swiper .swiper-button-prev,
  .swiper .swiper-button-next {
    display: none;
  }
}

.navbar {
  background: transparent !important;
  padding: 20px 0px;
  transition: all 0.8s ease;
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  font-family: "Jost", Arial, sans-serif;
}
.navbar .navbar-brand img {
  max-width: 170px;
}
.navbar .navbar-nav-scroll {
  margin-left: auto;
}
.navbar .nav-link {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  padding: 5px 20px !important;
}
.navbar .nav-link:after {
  content: "";
  display: block;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #49b747;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}
.navbar .nav-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.navbar .navbar-toggler {
  background: #309ad6;
}

.menubar-shrink .navbar {
  padding: 0;
  position: fixed;
  transition: all 0.8s ease;
  background: #fff !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.menubar-shrink .navbar .brand-wide {
  display: none;
}
.menubar-shrink .navbar .brand-shrink {
  display: block;
}
.menubar-shrink .navbar .brand-shrink.navbar-brand img {
  max-width: 120px;
}
.menubar-shrink .navbar .nav-link {
  color: #000;
}

.menubar-wide .navbar .brand-wide {
  display: block;
}
.menubar-wide .navbar .brand-shrink {
  display: none;
}
@media screen and (max-width: 990px) {
  .menubar-wide .navbar .navbar-collapse {
    background: #309ad6;
  }
}

.footer {
  padding: 50px 0px 0px;
  background: #212529;
  font-family: "Jost", Arial, sans-serif;
  font-size: 14px;
}
.footer .brand-logo {
  width: 70%;
  max-width: 200px;
}
@media screen and (max-width: 768px) {
  .footer .brand-logo {
    display: none;
  }
}
.footer .social-media {
  font-size: 28px;
  color: #309ad6;
  justify-content: center;
  margin-top: 30px;
}
.footer .social-media svg {
  margin-right: 10px;
}
.footer h1 {
  color: #49b747;
}
.footer .list-group {
  padding-right: 20px;
}
.footer .list-group .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
.footer .list-group .list-group-item a {
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 576px) {
  .footer .list-group {
    margin-bottom: 30px;
  }
}
.footer .footer-copyright {
  background: #000;
  text-align: center;
  padding: 10px;
  color: #fff;
  margin-top: 30px;
  font-size: 14px;
}

.about-us-container_banner {
  background: url(../images/banner/about-us-banner.jpg) center;
  background-size: cover;
  height: 90vh;
  display: flex;
  align-items: flex-end;
  color: #fff !important;
  padding-bottom: 100px;
}
.about-us-container h2 {
  margin-bottom: 15px;
}
.about-us-container .grey-container {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .about-us-container .grey-container {
    background: #f4f7f9 url("./../images/how-we-work.png") no-repeat;
    background-size: cover;
    background-blend-mode: soft-light;
  }
}
.about-us-container .grey-container img {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .about-us-container .grey-container img {
    display: none;
  }
}
.about-us-container .grey-container .banner-card {
  margin-left: -60px;
  margin-right: auto;
}
@media screen and (max-width: 991px) {
  .about-us-container .grey-container .banner-card {
    margin-left: 0;
  }
}
.about-us-container .about-services .card {
  border: transparent;
  background: #f4f7f9;
  margin: 70px 10px 40px;
}
.about-us-container .about-services .card .icon {
  background: #309ad6;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 45px;
  border-radius: 50%;
  margin-top: -60px;
  margin: -60px auto 0px;
}
.about-us-container .about-services .card p {
  padding-top: 10px;
  display: block;
  height: 200px;
}
@media screen and (max-width: 991px) {
  .about-us-container .about-services .card p {
    height: auto;
  }
}
.about-us-container .business-statergy {
  background: url(./../images/bussiness-stratergy-banner.jpg) fixed center no-repeat;
  background-size: cover;
  min-height: 500px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.about-us-container .business-statergy h2 {
  color: #fff;
  margin-bottom: 30px;
}

.services-container_banner {
  background: url(../images/services.jpg) top;
  background-size: cover;
  height: 90vh;
  display: flex;
  align-items: flex-end;
  color: #fff !important;
  padding-bottom: 100px;
}
.services-container h2 {
  margin-bottom: 15px;
}
.services-container .grey-container {
  display: flex;
  align-items: center;
}
.services-container .grey-container img {
  width: 100%;
}
.services-container .grey-container .banner-card {
  margin-left: -50px;
  margin-right: 0;
}
.services-container .grey-container .banner-card .list-group-item {
  background: transparent;
  border: 0;
  padding-left: 0;
  display: flex;
}
.services-container .grey-container .banner-card .list-group-item::before {
  content: "•";
  margin-right: 8px;
}
.services-container .banner-card.theme-banner-card {
  background: #309ad6;
}
.services-container .banner-card.theme-banner-card h2,
.services-container .banner-card.theme-banner-card p,
.services-container .banner-card.theme-banner-card .list-group-item {
  color: #fff;
}
.services-container .banner-card.theme-banner-card .list-group-item {
  background: transparent;
  border: 0;
  padding-left: 0;
  padding-right: 20px;
  display: flex;
}
.services-container .banner-card.theme-banner-card .list-group-item::before {
  content: "•";
  margin-right: 8px;
}
.services-container .services-data {
  background: url(./../images/servicess-small-banner-1.jpg) fixed no-repeat;
  background-size: cover;
  min-height: 600px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
  text-align: center;
}
.services-container .services-data h2 {
  color: #fff;
  margin-bottom: 30px;
}
.services-container .services-data button {
  margin: 0 auto;
  color: #fff;
  margin-top: 30px;
}
.services-container .inner-content.java-development-content {
  padding-top: 0;
}
@media screen and (max-width: 991px) {
  .services-container .inner-content img {
    display: none;
  }
  .services-container .inner-content .banner-card {
    margin: 0;
    padding: 0;
    background: transparent;
  }
  .services-container .inner-content .banner-card .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .services-container .inner-content.java-development-content, .services-container .inner-content.web-development-content {
    background: #309ad6;
  }
}

.contact-us-container_banner {
  background: url(../images/contact-us.jpg) bottom;
  background-size: cover;
  height: 90vh;
  display: flex;
  align-items: flex-end;
  color: #fff !important;
  padding-bottom: 100px;
}
.contact-us-container h2 {
  margin-bottom: 15px;
}
.contact-us-container .grey-container {
  display: flex;
  align-items: center;
}
.contact-us-container .grey-container img {
  width: 100%;
}
.contact-us-container .grey-container .banner-card {
  margin-left: -60px;
  margin-right: auto;
}
.contact-us-container .grey-container .form-control {
  border: 0;
  border-bottom: 1px solid #309ad6;
}
.contact-us-container .grey-container .form-control:focus {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .contact-us-container .grey-container {
    background: #f4f7f9 url("./../images/contactus.png") no-repeat;
    background-size: cover;
    background-blend-mode: soft-light;
  }
  .contact-us-container .grey-container img {
    display: none;
  }
}
.contact-us-container .business-statergy {
  background: url(./../images/bussiness-stratergy-banner.jpg) fixed;
  background-size: 100%;
  min-height: 500px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.contact-us-container .business-statergy h2 {
  color: #fff;
  margin-bottom: 30px;
}