.services-container {
  &_banner {
    background: url(../images/services.jpg) top;
    background-size: cover;
    height: 90vh;
    display: flex;
    align-items: flex-end;
    color: #fff !important;
    padding-bottom: 100px;
  }
  //   text-align: justify;
  h2 {
    margin-bottom: 15px;
  }
  .grey-container {
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
    .banner-card {
      margin-left: -50px;
      margin-right: 0;

      .list-group-item {
        background: transparent;
        border: 0;
        padding-left: 0;
        display: flex;
        &::before {
          content: "\2022";
          margin-right: 8px;
        }
      }
    }
  }
  .banner-card {
    &.theme-banner-card {
      background: $color-secondary;
      h2,
      p,
      .list-group-item {
        color: #fff;
      }
      .list-group-item {
        background: transparent;
        border: 0;
        padding-left: 0;
        padding-right: 20px;
        display: flex;
        &::before {
          content: "\2022";
          margin-right: 8px;
        }
      }
    }
  }
  .services-data {
    background: url(./../images/servicess-small-banner-1.jpg) fixed no-repeat;
    background-size: cover;
    min-height: 600px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 20px;
    text-align: center;
    h2 {
      color: #fff;
      margin-bottom: 30px;
    }
    button {
      margin: 0 auto;
      color: #fff;
      margin-top: 30px;
    }
  }
  .inner-content {
    &.java-development-content {
      padding-top: 0;
    }
    @media screen and (max-width: 991px) {
      img {
        display: none;
      }
      .banner-card {
        margin: 0;
        padding: 0;
        background: transparent;
        .card-body {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &.java-development-content,
      &.web-development-content {
        background: $color-secondary;
      }
    }
  }
}
