html,
body {
  font-family: $font-primary;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: $color-default;
}
h1 {
  font-size: 16px;
  line-height: 16px;
  // color: $color-primary;
  text-transform: uppercase;
  font-weight: 700;
  font-family: $font-secondary;
}
h2 {
  font-size: 32px;
  color: $color-primary;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
}
.main-container {
  margin-top: 0px;
  .who-we-are-content {
    background: $color-secondary;
    padding: 50px 0px;
    @media screen and (max-width: 991px) {
      background: $color-secondary url("./../images/who-we-are.png") no-repeat;
      background-size: cover;
      background-blend-mode: soft-light;
    }
    img {
      width: 100%;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .outline-success {
      border-radius: 6px;
      text-decoration: none;
      display: inline-block;
      margin-top: 20px;
      &:hover{
        background: $color-primary;
        color: #fff;
      }
    }
  }
  .banner-card {
    border: 0;
    margin-right: -50px;
    padding: 20px;
    @media screen and (max-width: 991px) {
      margin-right: 0;
    }
  }
  .home-our-services {
    padding: 50px 0px;
    .card {
      border: 0;
      margin: 10px 0px 30px;
      transition: transform 0.2s;
      overflow: hidden;
      cursor: pointer;
      .card-img-top {
        transition: all 0.3s ease-in-out;
      }
      .card-body {
        background: rgba(255, 255, 255, 0.8);
        margin: 10px;
        margin-top: -50px;
        font-size: 14px;
        position: relative;
        z-index: 9;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        .card-title {
          font-weight: 600;
          font-size: 23px;
          margin-bottom: 14px;
          font-family: $font-secondary;
          @media screen and (max-width: 990px) {
            font-size: 20px;
          }
        }
      }
      &:hover {
        .card-img-top {
          transform: scale(1.2);
        }
        .card-body {
          background: $color-secondary;
          color: #fff;
        }
      }
    }
  }
  .how-we-work-content {
    padding: 50px 0px;
    background: url(./../images/how-we-work.jpg) no-repeat fixed;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    min-height: 450px;
    color: #fff;
    text-align: center;
    h1 {
      margin-bottom: 50px;
    }
    .outline-success {
      border-radius: 6px;
      text-decoration: none;
      display: inline-block;
      margin-top: 20px;
      color: #fff;
      &:hover{
        background: $color-primary;
        color: #fff;
      }
    }
  }

  button, .anchor-button {
    font-family: $font-secondary;
    border: 2px solid $color-primary;
    // background: transparent;
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 10px 30px;
    display: flex;
    align-self: flex-end;
    margin-top: 40px;
  }
}
.inner-content {
  padding: 50px 0px;
}
.grey-container {
  background: #f4f7f9;
}
.brand-name {
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 18px;
}
.banner-container {
  h1 {
    font-size: 64px;
    line-height: 16px;
    color: #fff;
    text-transform: none;
    font-weight: 600;
    font-family: $font-secondary;
    margin-bottom: 50px;
  }
  h2 {
    font-size: 16px;
    color: #fff;
    font-family: $font-secondary;
    line-height: 29px;
  }
}
