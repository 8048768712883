.about-us-container {
  &_banner {
    background: url(../images/banner/about-us-banner.jpg) center;
    background-size: cover;
    height: 90vh;
    display: flex;
    align-items: flex-end;
    color: #fff !important;
    padding-bottom: 100px;
  }
  //   text-align: justify;
  h2 {
    margin-bottom: 15px;
  }
  .grey-container {
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      background: #f4f7f9 url("./../images/how-we-work.png") no-repeat;
      background-size: cover;
      background-blend-mode: soft-light;
    }
    img {
      width: 100%;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .banner-card {
      margin-left: -60px;
      margin-right: auto;
      @media screen and (max-width: 991px) {
        margin-left: 0;
      }
    }
  }
  .about-services {
    .card {
      border: transparent;
      background: #f4f7f9;
      margin: 70px 10px 40px;
      .icon {
        background: $color-secondary;
        height: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 45px;
        border-radius: 50%;
        margin-top: -60px;
        margin: -60px auto 0px;
      }
      p {
        padding-top: 10px;
        display: block;
        height: 200px;
        @media screen and (max-width: 991px) {
          height: auto;
        }
      }
    }
  }
  .business-statergy {
    background: url(./../images/bussiness-stratergy-banner.jpg) fixed center
      no-repeat;
    background-size: cover;
    min-height: 500px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 20px;
    h2 {
      color: #fff;
      margin-bottom: 30px;
    }
  }
}
