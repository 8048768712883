.swiper {
  // border:2px solid red;
  img {
    height: auto;
    width: 100%;
    @media screen and (max-width: 768px) {
      height: 100vh;
      width: auto;
    }
  }
  p {
    position: absolute;
    font-size: 40px;
    z-index: 9;
    top: 45%;
    color: #fff;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 990px) {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
