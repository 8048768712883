.contact-us-container {
  &_banner {
    background: url(../images/contact-us.jpg) bottom;
    background-size: cover;
    height: 90vh;
    display: flex;
    align-items: flex-end;
    color: #fff !important;
    padding-bottom: 100px;
  }
  //   text-align: justify;
  h2 {
    margin-bottom: 15px;
  }
  .grey-container {
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
    .banner-card {
      margin-left: -60px;
      margin-right: auto;
    }
    .form-control {
      border: 0;
      border-bottom: 1px solid $color-secondary;
      &:focus {
        box-shadow: none;
      }
    }

    @media screen and (max-width: 768px) {
      background: #f4f7f9 url("./../images/contactus.png") no-repeat;
      background-size: cover;
      background-blend-mode: soft-light;
      img {
        display: none;
      }
    }
  }
  .business-statergy {
    background: url(./../images/bussiness-stratergy-banner.jpg) fixed;
    background-size: 100%;
    min-height: 500px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 20px;
    h2 {
      color: #fff;
      margin-bottom: 30px;
    }
  }
}
