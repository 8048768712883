.footer {
  padding: 50px 0px 0px;
  background: #212529;
  font-family: $font-secondary;
  font-size: 14px;
  .brand-logo {
    width: 70%;
    max-width: 200px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .social-media {
    font-size: 28px;
    color: $color-secondary;
    justify-content: center;
    margin-top: 30px;
    svg {
      margin-right: 10px;
    }
  }
  h1 {
    color: $color-primary;
  }
  .list-group {
    padding-right: 20px;
    .list-group-item {
      padding-left: 0;
      padding-right: 0;
      a{
        text-decoration: none;
        color: #fff;
      }
    }
    @media screen and (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
  .footer-copyright {
    background: #000;
    text-align: center;
    padding: 10px;
    color: #fff;
    margin-top: 30px;
    font-size: 14px;
  }
}
